/**
 * CookieConsenter provides for querying cookie consent and listening for consent changes.
 */
import cookieMgr from 'js-cookie';
const MAIN_COOKIE = 'site-settings';
const TRACKING_SCRIPT_ID = 'threewire';
const browserEnv = (typeof window !== 'undefined');
const TARGETING_COOKIES = ['_ga', '_gid', '_gat_gtag_UA_206376107_2', '_fbp', 'fr'];
const COOKIES_CONFIRMED = 1;
const COOKIES_DECLINED = 0;
const COOKIES_NOT_SET = -1;

export default function CookieConsenter(pagePath, doLog) {
  // if (browserEnv) log(`Window contains tracking artifacts? ${(typeof window._fbq)}`);

  if (!pagePath) pagePath = '/';
  if (!browserEnv) {
    return {
      getConsent: function() {return COOKIES_CONFIRMED;}
    };
  }

  const utm = PageParamsTracker('UTM_INFO', ['utm_source', 'utm_medium', 'utm_content', 'utm_campaign', 'mpt']);

  /**
   * Search for consent cookie.
   * Return integer value where 1=confirmed, 0=declined, -1=unspecified
   * @return int
   */
  function getConsent() {
    const cookieValue = cookieMgr.get(MAIN_COOKIE);
    const consentCode = (cookieValue===undefined ? COOKIES_NOT_SET : Number(cookieValue));
    // log(`getConsent -> "${consentCode}", type: ${(typeof consentCode)}, cookie=${cookieValue}`);
    return consentCode;
  }

  function addScript(id, path) {
    const scriptTag = document.createElement('script');
    scriptTag.id = id;
    scriptTag.src = path;
    document.head.appendChild(scriptTag);
  }

  function removeTargetingCookies(cookiePath) {
    const targetingCookies = document.cookie.split('; ')
      .map(pair => pair.split('=')[0])
      .filter(item => TARGETING_COOKIES.includes(item));
    if (targetingCookies.length>0) {
      const docDomain = document.domain.substr(document.domain.indexOf('.'));
      targetingCookies.forEach(name => { cookieMgr.remove(name, {path: cookiePath || pagePath, domain: docDomain}); });
    }
  }

  function trackScripts(trackByDefault, pagePath) {
    const trackingTag = document.getElementById(TRACKING_SCRIPT_ID);
    const consentValue = getConsent();
    log(`trackScripts on page ${pagePath}, ${trackByDefault?'Track':'NO-Track'}, consent: "${consentValue}" Tracking tag exists=${Boolean(trackingTag)}`);
    if (trackByDefault) {
      if (consentValue===COOKIES_DECLINED) {
        log('TrackByDefault but cookies have been declined; remove tracking script.');
        removeTracking(trackingTag);
      } else {
        log(`TrackByDefault.. add tracking script (${(Boolean(trackingTag)?'exists':'need to load')})`);
        addTracking(trackingTag, pagePath);
      }
    } else {
      if (consentValue===COOKIES_CONFIRMED) {
        log(`NO-Track-Default but previously accepted; add tracking script. ${(Boolean(trackingTag)?'exists':'need to load')}`);
        addTracking(trackingTag, pagePath);
      } else {
        removeTracking(trackingTag);
      }
    }
  }

  // Add "refresh" by removing, then adding script
  function addTracking(script, pagePath) {
    if (script) script.remove();
    addScript(TRACKING_SCRIPT_ID, '/threewire.js');
    if (pagePath) utm.init(pagePath);
  }

  function removeTracking(script) {
    if (script) {
      script.remove();
      utm.clear();
      if (browserEnv) window.location.reload(true); // Reload page from server to refresh window object
    }
  }

  function confirm() {
    cookieMgr.set(MAIN_COOKIE, COOKIES_CONFIRMED);
    addTracking(document.getElementById(TRACKING_SCRIPT_ID), window.location.href);
  }

  function decline() {
    cookieMgr.set(MAIN_COOKIE, COOKIES_DECLINED);
    removeTracking(document.getElementById(TRACKING_SCRIPT_ID));
    removeTargetingCookies();
  }

  function log(message) { if (doLog) console.log(`>> [${new Date().toLocaleTimeString()}] ${message}`); }

  return { getConsent, confirm, decline, trackScripts, COOKIES_CONFIRMED, COOKIES_DECLINED, COOKIES_NOT_SET };
};

function PageParamsTracker(cookieName, cookieFields) {
  /**
   * Parse url into a delimited string of parameters, filtering out any unregistered values.
   * @param string - the query string parameters received from the referrer
   * @return string
   */
  function parseParams(source) {
    if (!source) return '';
    const parts = source.split('?');
    if (parts.length<2) return '';
    return parts[1].split('&').filter(pair => cookieFields.includes(pair.split('=')[0])).join('|');
  }

  const docDomain = (document.domain==='localhost' ? 'localhost' : document.domain.substr(document.domain.indexOf('.')).replace('/', ''));

  function init(pagePath) {
    const originalUtm = parseParams(pagePath);
    const newvalue = originalUtm || get();
    cookieMgr.set(cookieName, (newvalue==='undefined' ? '': newvalue));
  }

  function clear() {
    if (get()) cookieMgr.remove(cookieName, {path: '/', domain: docDomain});
    if (get()) cookieMgr.set(cookieName, '');
  }

  function get() { return cookieMgr.get(cookieName); }

  return { get, init, clear };
}
